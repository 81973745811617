<template>
    <div>
        <div id="container"></div>
    </div>
</template>

<script>
export default {
    methods: {
        data() {
            return {
                map: {},
            };
        },
        init() {
            var map = new TMap.Map(document.getElementById("container"), {
                center: new TMap.LatLng(39.916527, 116.397128), // 地图的中心地理坐标。
                zoom: 8, // 地图的中心地理坐标。
            });
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang="less" scoped></style>
